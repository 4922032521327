import React from 'react';
import Panel from '../panel';
import { Seq } from 'immutable';
import PropTypes from 'prop-types';

import {
    MODE_IDLE, MODE_2D_ZOOM_IN, MODE_2D_ZOOM_OUT, MODE_2D_PAN, MODE_3D_VIEW, MODE_3D_FIRST_PERSON,
    MODE_WAITING_DRAWING_LINE, MODE_DRAWING_LINE, MODE_DRAWING_HOLE, MODE_DRAWING_ITEM, MODE_DRAGGING_LINE,
    MODE_DRAGGING_VERTEX, MODE_DRAGGING_ITEM, MODE_DRAGGING_HOLE, MODE_FITTING_IMAGE, MODE_UPLOADING_IMAGE,
    MODE_ROTATING_ITEM
} from '../../../constants';
import { ElementProductList } from '../../product/element-product-list';

export const PanelElementProducts = ({ state }, { projectActions, translator }) => {
    const { scene, mode } = state;

    if (![MODE_IDLE, MODE_2D_ZOOM_IN, MODE_2D_ZOOM_OUT, MODE_2D_PAN,
        MODE_3D_VIEW, MODE_3D_FIRST_PERSON,
        MODE_WAITING_DRAWING_LINE, MODE_DRAWING_LINE, MODE_DRAWING_HOLE, MODE_DRAWING_ITEM,
        MODE_DRAGGING_LINE, MODE_DRAGGING_VERTEX, MODE_DRAGGING_ITEM, MODE_DRAGGING_HOLE,
        MODE_ROTATING_ITEM, MODE_UPLOADING_IMAGE, MODE_FITTING_IMAGE].includes(mode)) return null;

    let componentRenderer = (element, layer) => {
        return (
            <Panel key={element.id} name={translator.t('Products: [{0}] {1}', element.type, element.id)} opened={true}>
                <ElementProductList
                    cart={scene.get("cart")}
                    categories={element.categories}
                    layer={scene.layers.get(scene.selectedLayer)}
                    loadingText={translator.t("Loading")}
                    emptyText={translator.t("No products")}
                />
            </Panel>
        )
    };

    let layerRenderer = layer => Seq()
        .concat(layer.lines, layer.holes, layer.areas, layer.items)
        .filter(element => element.selected)
        .map(element => componentRenderer(element, layer))
        .valueSeq();

    return <div>{scene.layers.valueSeq().map(layerRenderer)}</div>
}

export default PanelElementProducts;

PanelElementProducts.propTypes = {
    state: PropTypes.object.isRequired,
};

PanelElementProducts.contextTypes = {
    projectActions: PropTypes.object.isRequired,
    translator: PropTypes.object.isRequired
};