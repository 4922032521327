import React from 'react';
import PropTypes from 'prop-types';
import { IMAGES_URL } from '../../constants';

const STYLE_PRODUCT = {
    display: "flex",
    flexDirection: "row",
    gap: 5,
    padding: '10px 15px',
    borderBottom: '1px solid rgb(136, 136, 136)',
    cursor: "pointer"
}

const STYLE_PRODUCT_IMAGE = {
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: "20%",
    minWidth: "20%"
}

const STYLE_PRODUCT_INFO = {
    display: "flex",
    flexDirection: "column",
    position: "relative"
}

const STYLE_PRODUCT_ATTR = { fontStyle: "italic", fontSize: 10 }

export function ElementProduct({ product, layer, cart }, { itemsActions }) {
    if (!product || !layer) return null

    const { selected } = layer

    const itemID = selected.get("items").first()

    const isAddedCart = cart.has(product.id.toString());

    return (
        <div
            style={STYLE_PRODUCT}
            onClick={e => itemsActions.selectProductItem(layer.id, itemID, product.id)}
        >
            <div
                style={{
                    ...STYLE_PRODUCT_IMAGE,
                    backgroundImage: `url(${IMAGES_URL}${product.image})`,
                }}
            />
            <div style={STYLE_PRODUCT_INFO}>
                <span style={{ marginBottom: 3, fontWeight: 500 }}>{product.name}</span>
                {product.category && <small style={STYLE_PRODUCT_ATTR}>Категория: {product.category.name}</small>}
                <small style={STYLE_PRODUCT_ATTR}>Цена: {product.price} ₽</small>
                <small style={STYLE_PRODUCT_ATTR}>Рейтинг: {product.rating}</small>
                <small style={STYLE_PRODUCT_ATTR}>Продавец: {product.seller.store_name}</small>
                <div style={{ position: "absolute", right: 1, bottom: 1 }}>
                    <small style={{ color: "#ccc" }}>{isAddedCart ? "Удалить" : "В корзину"} </small>
                </div>
            </div>
        </div>
    )
}

ElementProduct.propTypes = {
    product: PropTypes.object.isRequired,
    layer: PropTypes.object.isRequired,
    cart: PropTypes.object,
};

ElementProduct.contextTypes = {
    projectActions: PropTypes.object.isRequired,
    viewer2DActions: PropTypes.object.isRequired,
    viewer3DActions: PropTypes.object.isRequired,
    linesActions: PropTypes.object.isRequired,
    holesActions: PropTypes.object.isRequired,
    itemsActions: PropTypes.object.isRequired,
    translator: PropTypes.object.isRequired,
};