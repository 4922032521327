import regeneratorRuntime from "regenerator-runtime"; // не удалять
import axios from 'axios';

const API_URL = 'https://planner.yakdu.ru/api/v1';

// helpers
const objToURLParams = (obj = {}) => {
  const entries = Object.entries(obj);
  let params = "";
  for (const [key, value] of entries) {
    if (typeof value === "undefined") continue;
    params += `${params.length ? "&" : "?"}${key}=${value}`;
  }

  return params;
}
// end helpers

export const fetchProductVariants = async (query = {}) => {
  const { categories = [], ids = [], scope = [] } = query;

  const paramsObj = {
    categories: categories.length ? categories.join(",") : undefined,
    ids: ids.length ? ids.join(",") : undefined,
    scope: scope.length ? scope.join(",") : undefined,
  }

  try {
    const result = await axios.get(`${API_URL}/productVariants${objToURLParams(paramsObj)}`);
    return result.data;
  } catch (error) {
    console.log(error);
    return [];
  }
}
