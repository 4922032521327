import regeneratorRuntime from "regenerator-runtime"; // не удалять

import React, { useEffect, useState } from "react"
import { ElementProduct } from "./element-product";
import PropTypes from 'prop-types';
import { fetchProductVariants } from "../../api";

const STYLE_NO_PRODUCTS = { padding: '10px 15px' }

export const ElementProductList = React.memo(({ cart, categories = [], layer, loadingText = "Loading", emptyText = "No products" }) => {
    const [products, setProducts] = useState(null);

    useEffect(() => {
        if (!categories || !categories.length) {
            setProducts([]);
            return
        }

        const getProducts = async () => {
            const result = await fetchProductVariants({ categories, scope: ['category', 'seller'] });

            setProducts(result)
        }
        getProducts()

    }, [])

    if (!products) return <p style={STYLE_NO_PRODUCTS}>{loadingText}...</p>

    if (!products.length) return <p style={STYLE_NO_PRODUCTS}>{emptyText}</p>

    return products.map((product) => <ElementProduct key={`panel_element_product_${product.id}`} product={product} layer={layer} cart={cart} />)
})

ElementProductList.propTypes = {
    cart: PropTypes.object,
    categories: PropTypes.array,
    layer: PropTypes.object.isRequired,
    loadingText: PropTypes.string,
    emptyText: PropTypes.string,
};