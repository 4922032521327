import regeneratorRuntime from "regenerator-runtime"; // не удалять

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { MdShoppingCart } from 'react-icons/md';
import { fetchProductVariants } from "../../api";

import * as SharedStyle from '../../shared-style';
import { IMAGES_URL } from "../../constants";

const STYLE = {
    width: '30px',
    height: '30px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '5px',
    fontSize: '25px',
    position: 'relative',
    cursor: "pointer"
};

const STYLE_TOOLTIP = {
    position: 'absolute',
    width: 240,
    color: SharedStyle.COLORS.white,
    background: SharedStyle.PRIMARY_COLOR.main,
    height: 250,
    textAlign: 'left',
    visibility: 'visible',
    borderRadius: '6px',
    opacity: '1',
    left: '100%',
    bottom: '50%',
    marginTop: '-15px',
    marginLeft: 35,
    zIndex: '9999999',
    fontSize: '12px',
    overflowY: "auto",
    transition: "opacity 0.2s ease",

};

const STYLE_TOOLTIP_PIN = {
    position: 'absolute',
    bottom: 0,
    right: '100%',
    marginTop: '-8px',
    width: '0',
    height: '0',
    borderRight: '8px solid #28292d',
    borderTop: '8px solid transparent',
    borderBottom: '8px solid transparent'
};

const STYLE_TOOLTIP_HEADER = {
    position: "sticky",
    top: 0,
    width: "100%",
    padding: 7,
    background: SharedStyle.PRIMARY_COLOR.main,
    zIndex: 10,
    borderBottom: "1px solid #3d3d3d",
    textAlign: "center"
}

const STYLE_TOOLTIP_PRODUCT = {
    display: "flex",
    flexDirection: "row",
    gap: 5,
    padding: '10px 15px',
    borderBottom: "1px solid #3d3d3d"
}

const STYLE_TOOLTIP_PRODUCT_IMAGE = {
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: "20%",
    minWidth: "20%",
    maxHeight: 50
}

const STYLE_TOOLTIP_PRODUCT_ATTR = { fontStyle: "italic", fontSize: 10 }

const CartTotals = ({ products }) => {
    if (!products) return null

    const sum = products.reduce((a, v) => a += v.price, 0);

    return (
        <span style={{
            position: "absolute",
            top: -12,
            right: 0,
            fontSize: 9,
            zIndex: 15,
            background: "red",
            color: "#fff",
            borderRadius: 6,
            padding: "1px 2px"
        }}
        >
            {sum}₽
        </span>
    )
}

const CartTooltip = ({ show, products, emptyText = "No products", onClick, ...props }) => {

    const handleClick = (product) => {
        if (!onClick) return;
        onClick(product.cartData.layerID, product.cartData.itemID);
    }

    if (!show) return null;

    return (
        <div style={STYLE_TOOLTIP} {...props}>
            <span style={STYLE_TOOLTIP_PIN} />
            <div style={STYLE_TOOLTIP_HEADER}>
                <span>Корзина</span>
            </div>
            {products.length ? products.map((product) => (
                <div
                    key={`toolbar_cart_product_${product.id}`}
                    style={STYLE_TOOLTIP_PRODUCT}
                    onClick={() => handleClick(product)}
                >
                    <div style={{
                        ...STYLE_TOOLTIP_PRODUCT_IMAGE,
                        backgroundImage: `url(${IMAGES_URL}${product.image})`,
                    }} />
                    <div style={{ display: "flex", flexDirection: "column", position: "relative" }}>
                        <span style={{ marginBottom: 3, fontWeight: 500, fontSize: 10 }}>{product.name}</span>
                        <small style={STYLE_TOOLTIP_PRODUCT_ATTR}>Цена: {product.price} ₽</small>
                        <small style={STYLE_TOOLTIP_PRODUCT_ATTR}>Продавец: {product.seller.store_name}</small>
                    </div>
                </div>
            )) :
                <div style={{ padding: '10px 15px', display: "flex", justifyContent: "center", width: "100%" }}>
                    <small>{emptyText}</small>
                </div>}
        </div>
    )

}

export const ToolbarCart = ({ cart }, { translator, itemsActions }) => {
    const cartJS = cart.toJS();

    const [products, setProducts] = useState();
    const [active, setActive] = useState(false);
    const [showTooltip, setShowTooltip] = useState(false);

    useEffect(() => {
        const ids = Object.keys(cartJS).filter(String);

        if (!ids.length) {
            setProducts([]);
            return;
        }

        async function getProductsByIds() {
            const result = await fetchProductVariants({ ids, scope: ['seller'] })

            let finishProducts = [];
            for (const product of result) {
                finishProducts.push({ ...product, cartData: cartJS[product.id] })
            }

            setProducts(finishProducts)
        }
        getProductsByIds();
    }, [cart]);

    useEffect(() => {
        if (active) {
            clearTimeout(delayDebounceFn);
            return
        }
        const delayDebounceFn = setTimeout(() => {
            setShowTooltip(false)
        }, 300)

        return () => clearTimeout(delayDebounceFn)
    }, [active])

    const handleMouseOver = () => {
        setActive(true);
        setShowTooltip(true);
    }

    const handleMouseOut = () => {
        setActive(false)
    }

    return (
        <div style={{ position: "absolute", bottom: 25 }}>
            <div
                style={STYLE}
                onMouseOver={handleMouseOver}
                onMouseOut={handleMouseOut}
            >
                <div style={{ color: !showTooltip ? SharedStyle.PRIMARY_COLOR.icon : SharedStyle.SECONDARY_COLOR.icon }}>
                    <CartTotals products={products} />
                    <MdShoppingCart />
                </div>
                <CartTooltip
                    show={showTooltip}
                    products={products}
                    onMouseOver={handleMouseOver}
                    onMouseOut={handleMouseOut}
                    onClick={itemsActions.selectItem}
                    emptyText={translator.t("No products")}
                />
            </div>
        </div>
    )
}

ToolbarCart.propTypes = {
    cart: PropTypes.object.isRequired,
}

ToolbarCart.contextTypes = {
    itemsActions: PropTypes.object.isRequired,
    translator: PropTypes.object.isRequired,
};